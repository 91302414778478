import React from "react";
import i18n from "../../translator";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Background from "../../components/Background";
import NavigationBar from "../../components/NavigationBar";
import ErrorMessagePopup from "./Error";

class App extends React.Component {
  render() {
    return (
      <HelmetProvider>
        <Helmet htmlAttributes={{ lang: i18n.resolvedLanguage }}>
          <title>{i18n.t("error404")}</title>
          <meta name="description" content={i18n.t("error404") || ""} />
        </Helmet>
        <div className="main">
          <Background />
          <NavigationBar />
          <ErrorMessagePopup />
        </div>
      </HelmetProvider>
    );
  }
}

export default App;
