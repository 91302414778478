import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: {
          pageTitle: `Samantha Azoulay Real-Estate - Apartments in Israel, Real Estate Agent In Israel, Properties in Israel`,
          pageDescription: `Explore premier real estate in Israel with Samanth Azoulay. Buy, sell, rent, or invest with confidence. Your ideal property awaits!`,
          home: "Home",
          "about-me": "About Me",
          apartments: "Apartments",
          name: `Samantha Azoulay`,
          "real-estate": "Real Estate",
          "first-line-hero-section":
            "Are you looking for an apartment in Israel?",
          "second-line-hero-section":
            "Do you need help finding a buyer or a seller?",
          "about-title": "Meet Samantha",
          "about-content": `Since entering the real estate industry about a decade ago, Samantha has built an unparalleled
                        reputation for providing her clients with impeccable five-star service and exceptional results with
                        selling, renting, buying, and even renovating properties.
                        Samantha is easily reachable and speaks 3 languages (Hebrew, English, and French)
                        Samantha's success is based on hard work, her extensive market knowledge,
                        and not to mention her deep connections.
                        Over the years, she became a well-regarded realtor and an industry innovator.
                        Everything from purchasing and selling your home, to a leaky faucet, to a complete demo & renovation…
                        From real-estate lawyers, the appraiser, and the mortgage advisor.`,
          reliability: "Reliability",
          "reliability-text": "You can count on me for whatever you need!",
          "for-any-purpose": "For Any Purpose",
          "for-any-purpose-text": "For good investments and living!",
          "multi-language-support": "Multi Language Support",
          "multi-language-support-text": "Hebrew, English and French",
          "under-maintenance": "Website is Under Maintenace",
          "number-of-rooms": "Number of rooms",
          "number-of-bedrooms": "Number of bedrooms",
          "number-of-bathrooms": "Number of bathrooms",
          "square-feet": "Total square feet",
          copyright: `Copyright © Developed by Noam Azoulay.`,
          // Accessibility
          bigCursor: "Big Cursor",
          closeTitle: "Close",
          decreaseLineHeight: "Decrease Line Height",
          resetTitle: "Reset",
          menuTitle: "Accessibility Menu",
          increaseLineHeight: "Increase Line Height",
          speechToText: "Speech To Text",
          increaseText: "Increase Text",
          decreaseText: "Decrease Text",
          invertColors: "Invert Colors",
          increaseTextSpacing: "Increase Text Spacing",
          decreaseTextSpacing: "Decrease Text Spacing",
          grayHues: "Gray Hues",
          disableAnimations: "Disable Animations",
          readingGuide: "Reading Guide",
          screenReader: "Screen Reader",
          textToSpeech: "Text To Speech",
          underlineLinks: "Underline Links",
          sold: "Sold",
          error404: "Page Not Found",
          error404Message: "The page you are looking for does not exist.",
        },
      },
      he: {
        translations: {
          pageTitle: `סמנטה אזולאי נדל"ן - דירות למכירה, דירות להשכרה, נכסים להשקעה`,
          pageDescription: `סמנטה אזולאי נדל"ן מציעה לכם את הנכסים הטובים ביותר בשוק הנדל"ן בישראל. קנייה, מכירה, השכרה והשקעה בביטחון. הנכס האידיאלי מחכה לכם!`,
          home: "דף הבית",
          "about-me": "אודותיי",
          apartments: "דירות",
          name: `סמנטה אזולאי`,
          "real-estate": 'נדל"ן',
          "first-line-hero-section": "מחפשים לקנות או להשכיר דירה?",
          "second-line-hero-section":
            "מעוניינים לבחון נכס לצורך מכירה או השכרה?",
          "about-title": "הכירו את סמנטה",
          "about-content": `מאז כניסתה אל תחום עסקי הנדל"ן לפני עשור, סמנטה בנתה את תדמיתה ע"י נתנית שירות מעולה ללקוחותיה,
                        עם תוצאות יוצאות דופן, במכירה, השכרה, קנייה ואף חידוש דירות לשם העלאת ערכם.
                        סמנטה דוברת כ3 שפות (עברית, אנגלית וצרפתית) ובכך זמינה לקהל רחב יותר של לקוחות.
                        הצלחתה בנויה על עבודה קשה והידע הרחב שלה בשוק הנדל"ן יחד עם קשרייה המרובים.
                        דואגת ועוזרת לכל מה שמסביב, כולל עורכי דין, יועצי משכנתאות ושמאים.`,
          reliability: "בהתחייבות",
          "reliability-text": "תוכלו לסמוך עליי בכל דבר ועניין בעסקה!",
          "for-any-purpose": "לכל מטרה",
          "for-any-purpose-text": "לחיפוש דירה, מכירות, השכרות ואפילו השקעות",
          "multi-language-support": "דוברת שפות",
          "multi-language-support-text": "עברית, אנגלית וצרפתית.",
          "under-maintenance": "האתר תחת עבודות תחזוקה.",
          "number-of-rooms": "חדרים",
          "number-of-bedrooms": "חדרי שינה",
          "number-of-bathrooms": "מקלחות / שירותים",
          "square-feet": "מטר רבוע",
          copyright: `.פותח ע"י נועם אזולאי © כל הזכויות שמורות`,
          // Accessibility
          bigCursor: "מצביע גדול",
          closeTitle: "סגור",
          decreaseLineHeight: "הקטן את גובה השורה",
          resetTitle: "איפוס",
          menuTitle: "תפריט נגישות",
          increaseLineHeight: "הגדל את גובה השורה",
          speechToText: "הפוך דיבור לטקסט",
          increaseText: "הגדל את הטקסט",
          decreaseText: "הקטן את הטקסט",
          invertColors: "הפוך צבעים",
          increaseTextSpacing: "הגדל את ריווח הטקסט",
          decreaseTextSpacing: "הקטן את ריווח הטקסט",
          grayHues: "גווני אפור",
          disableAnimations: "בטל אנימציות",
          readingGuide: "מדריך קריאה",
          screenReader: "קורא מסך",
          textToSpeech: "הפוך טקסט לדיבור",
          underlineLinks: "קו תחתון לקישורים",
          sold: "נמכר",
          error404: "דף לא נמצא",
          error404Message: "הדף שאתה מחפש לא נמצא.",
        },
      },
    },
    fallbackLng: "en",
    debug: false,
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
