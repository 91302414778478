import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NavigationBar from "../components/NavigationBar";
import Background from "../components/Background";
import HeroSection from "../components/HeroSection";
import AboutMe from "../components/AboutMe";
import FeatureSection from "../components/FeatureSection";
import ApartmentSection from "../components/ApartmentSection";
import CopyrightSection from "../components/CopyrightSection";
import "../components/styles/common.css";
import i18n from "../translator";

export class Main extends React.Component {
  render() {
    return (
      <HelmetProvider>
        <Helmet htmlAttributes={{ lang: i18n.resolvedLanguage }}>
          <title>{i18n.t("pageTitle")}</title>
          <meta name="description" content={i18n.t("pageDescription") || ""} />
        </Helmet>
        <div className="main">
          <Background />
          <NavigationBar />
          <HeroSection />
          <AboutMe />
          <FeatureSection />
          <ApartmentSection />
          <CopyrightSection />
        </div>
      </HelmetProvider>
    );
  }
}

export class MainEng extends React.Component {
  constructor(props: any) {
    i18n.changeLanguage("en");
    super(props);
  }

  render() {
    return <Main />;
  }
}

export class MainHeb extends React.Component {
  constructor(props: any) {
    i18n.changeLanguage("he");
    super(props);
  }

  render() {
    return <Main />;
  }
}

export default Main;
