import React from "react";
import i18n from "../../translator";
import "./styles.css"

class ErrorMessagePopup extends React.Component {
  render() {
    return (
        <div className="error-section">
            <div className="error-box">
                <h1>{i18n.t("error404")}</h1>
                <h2>{i18n.t("error404Message")}</h2>
            </div>
        </div>
    );
  }
}

export default ErrorMessagePopup;
